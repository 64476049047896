body{
  font-family: 'Roboto', sans-serif;
  padding-bottom: 40px;
  height: auto!important;
  background-color: #f2f2f2;
}
.content{
  margin-top: 25px;
  position: relative;
}
.row{
  margin: 25px auto;
  background: white;
  border-radius: 10px;
  padding: 1em;
  &--container{
    border: 1px solid #dedddd;
    box-shadow: 0 2px 5px #c5c9d0;
  }
}
.top-bar{
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:after, &:before{
    content: none;
  }
  .top-bar-left, .top-bar-right{
    width: auto;
  }
  ul{
    background-color: inherit;
  }
  a{
    color: $white!important;
  }
  &.primary{
    background-color: lighten($black, 15%);
  }
  &.primary li{
    &:hover{
      background-color: lighten($black, 20%);
    }
    &:active{
      background-color: lighten($black, 25%);
    }
  }
  .fa{
    margin: 0 5px;
  }
  &__home{
    background-color: white;
    border-radius: 5px;
    a{
      color: $primary!important;
    }
  }
}
.page{
  &__title{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    clear: both;
    &:after{
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: lighten(black, 85%);
      margin: auto;
    }
  }
  &__subtitle{
    text-align: center;
    font-size: 2.2em;
    clear: both;
    text-align: center;
  }
}
.button{
  background-color: $primary;
  .fa{
    margin-right: 5px;
  }
}
footer{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $primary;
  color: $white;
  p{
    margin: 0;
    padding: 10px 0;
    font-size: 12px;
  }
  a{
    color: inherit;
  }
}

.top-bar-right__item{
  &:after{
    content: '';
    width: 100%;
    height: 1px;
    background: transparent;
    display: block;
    margin-top: 5px;
  }
  &:active{
    background-color: darken($primary, 8%);
  }
}

.top-bar-right__item:hover:after{
    background: white;
}

.button-half{
  width: 45%;
  float: left;
  margin: 0 10px;
}

.button.alert{
  background-color: $admin-red;
  &:hover{
    background-color: lighten($admin-red, 10%);
  }
}

.required-field{
  color: $admin-red;
}

.institution__link{
  list-style: none;
  float: left;
  border: 1px solid $link;
  border-radius: 5px;
  text-align: center;
  margin: 30px;
  &:hover{
    background-color: $link;
  }
  a{
    padding: 1em 3em;
    width: 75px;
    &:hover{
      color: white!important;
    }
  }
  &--wrapper{
    display: table;
    margin: auto;
  }
}

.student__list{
  margin: 2em 0;
  height: 750px;
    display: table;
    clear: both;
    position: relative;
  h3{
    font-size: 1.5em;
    margin-bottom: 1.5em;
    color: $primary;
  }
  .dataTables_filter{
    float: left;
  }
}

.student__item{
  cursor: pointer;
  margin-left: -35px;
  color: lighten(black, 20%);
  &:hover{
    color: black;
  }
}

.student__checkbox{
  position: absolute;
  left: -9999px;
}

#students-removed i{
  margin-right: 15px;
  padding: .5em;
  color: lighten($admin-green, 5%);
  &:hover{
    // background-color: #eee;
    color: $admin-green;
  }
}

#students-added i{
  margin-right: 15px;
  padding: .5em;
  color: lighten($admin-red, 5%);
  &:hover{
    // background-color: #eee;
    color: $admin-red;
  }
}

@mixin popup-s {
    color: lighten($primary, 65%);
    clear: both;
    padding: 0.4em 1em;
    display: block;
    min-width: 40%;
    margin-bottom: 0.5em;
    float: left;
    border-radius: 20px;
    i{
      margin-left: 1em;
    margin-top: 5px;
      float: right;
    cursor: pointer;
    &:hover{
      color: white;
    }
    }
}
.popup{
  &--container{
    position: fixed;
      left: 25px;
      bottom: 50px;
      // span{
      // 	margin-left: 1em;
      // 	text-transform: uppercase;
      // 	color: lighten($primary, 50%);
      // 	cursor: pointer;
      // 	&:hover{
       //  	color: lighten($primary, 58%);
      // 	}
      // }
  }
  &__name{
    color: white;
  }

    &--success{
      @include popup-s;
      background-color: rgba($primary, .85);
    }
    &--failure{
      @include popup-s;
      background-color: $admin-red;
    }
}

//DataTables custom styles
.dataTables_filter{
  label{
    text-align: left;
    // margin-left: 1em;
  }

}

.dataTables_wrapper .dataTables_filter{
  input{
      // margin: 0 0 1em;
  }
}

.dataTables_info{
  font-size: 0.9em;
  color: lighten(black, 45%)!important;
}

.login__password{
  position: relative;
  i{
    font-size: 1.5em;
    margin-left: 4px;
  }
  input{
    padding-right: 70px;
  }
}
.show-password{
  align-items: center;
  background-color: darken($light-gray, 10%);
  border: 1px solid #cacaca;
  bottom: 0;
  color: darken($light-gray, 50%);
  display: flex;
  justify-content: center;
  width: 70px;
  position: absolute;
  right: 0;
  top: 25px;
  transition: width 0.3s ease;
  &:before{
    content: 'Ver';
    display: block;
  }
  &:hover{
    background-color: darken($light-gray, 15%);
  }
  .show-icon{
    &:before{
      content: "\f06e";
    }
  }
}
.show-password--active{
  box-shadow: inset 0 0 10px transparentize($color: black, $amount: 0.5);
  background-color: darken($light-gray, 60%);
  color: $light-gray;
  width: 95px;
  &:before{
    content: 'Ocultar';
    display: block;
  }
  &:hover{
    background-color: darken($light-gray, 60%);
  }
  .show-icon{
    &:before{
      content: "\f070";
    }
  }
}
.login-after{
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.reset-password{
  color: $link;
  font-size: 15px;
  i{
    margin-left: 8px;
  }
}
.table__medal{
  max-width: 80px;
  position: relative;
  cursor: pointer;
}
.error-message{
  color: red;
  font-size: 14px;
  i{
    margin-right: 0.5em;
  }
}
.medal-upload-form{
  position: relative;
  display: flex;
  label{
    margin: auto;
  }
}
.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.95);
  display: flex;
    &:after{
      content: '';
      margin: auto;
      display: block;
      border: 5px solid $light-gray; /* Light grey */
      border-top: 5px solid $primary; /* Blue */
      border-radius: 50%;
      width: 15px;
      height: 15px;
      animation: spin 2s linear infinite;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.tabs-title{
  a{
    font-size: 1em;
    color: $primary;
  &:hover{
      background: $light-gray;
    }
  }
}

.is-active{
  display:block;
}
.table.is-active{
  display: table;
  th[style]{
    width: auto;
  }
}
#date-range.is-active{
  display: flex;
}

.tabs-title.is-active{
  background: $light-gray;
}

.tabs-content{
  display: flex;
  margin: 0.937em;
  padding-top: 2em;
}
.tabs-panel{
  width: 100%;
}
.medal-section__description{
  margin-bottom: 3em;
  i{
    color: $dark-gray;
  }
}
.level__degree{
  text-transform: capitalize;
}

// Teacher styles
#filter-radio-container{
  display: none;
}

#date-range{
  display: none;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin: 30px 0;

  [type=date]{
    width: 30%;
    margin-bottom: 0;
  }
}

.section-form{
  position: relative;
  &--inactive{
    &:after{
      content: 'Por favor complete los campos de arriba';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparentize(white, 0.05);
    }
  }
}

.login--container{
  width: 100%;
  height: 100%;
  height: calc(100vh - 100px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-box{
    background-image: url('../images/ingreso.png');
    background-size: cover;
    background-position: top center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    width: 900px;
    height: 690px;

    @include media(1200px){
      width: 1000px;
      height: 750px;
    }
    &-element{
      width: 60%;
    }
  }
}

.login__title{
  color: $forest-green;
  font-family: $title-font-family;
  font-weight: bold;
  margin: 2em auto .75em;
  &:after{
    content: '';
    background-color: $lime-green;
    width: 35px;
    height: 4px;
    display: block;
    margin: .25em auto;
  }
}

.body-login{
  .top-bar{
    z-index: 1;
    background: transparent;
  }

  .top-bar-right__item{
    color: $primary!important;
  }

  footer{
    background: transparent;
  }

  .text-center{
    color: $primary;
  }

  &:before{
    content: url('../images/login-bg-1.png');;
    position: absolute;
    top: 100px;
    left: 25px;
    z-index: -1;
  }

  &:after{
    content: url('../images/login-bg-2.png');;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: -1;
  }

  button{
    background-color: $forest-green;
    &:hover{
      background-color: darken($forest-green, 10%);
    }
  }
}

.alert-success{
  position: fixed;
  bottom: 50px;
  background-color: $admin-green;
  z-index: 10;
  padding: 1em;
  border-radius: 10px;
  color: white;
}
