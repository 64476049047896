table.dataTable{
	border-bottom: 1px solid lighten($black, 80%)!important;
	margin-bottom: 2em;
	a{
		color: $primary;
	}
	th{
		border-bottom: 1px solid lighten($black, 80%)!important;
	}
}
.dataTables_length{
	// width: 30%;
	select{
		display: inline-block;
		width: 60px;
		margin: 0 10px;
	}
}
.dataTables_filter{
	input{
		display: inline-block;
		width: 200px;
	}
}